<template>
    <img :id="id" v-if="src !== null && !error" :src="src" @click="$emit('click')" @load="$emit('load')">
    <div v-else-if="error">
        <slot></slot>
    </div>
</template>

<script>
import authHeader from '@/services/auth-header';
export default {
    name: "SecureImage",
    props: {
        filename: String,
        storage_account: String,
        site_id: String,
        client_id: String,
        id: String,
        endpoint: {
            type: String,
            required: false,
        }
    },
    data(){
        return {
            src: null,
            error: false
        }
    },
    mounted() {

        let API_ENDPOINT = '/api/event/thumbnail/';
        if(this.endpoint !== undefined){
            if(this.endpoint.length > 0){
                API_ENDPOINT = this.endpoint;
            }
        }

        this.loadImage();

    },
    methods: {
          loadImage(){
              let API_ENDPOINT = '/api/event/thumbnail/';
              if(this.endpoint !== undefined){
                  if(this.endpoint.length > 0){
                      API_ENDPOINT = this.endpoint;
                  }
              }

              axios.get(`https://api.varsanpr.com${API_ENDPOINT}${this.filename}?site_id=${this.site_id}&client_id=${this.client_id}&storage_account=${this.storage_account || 1}`, {
                  responseType: 'arraybuffer',
                  headers: authHeader()
              })
                  .then(response => {
                    if(this.src?.length > 0){
                        window.URL.revokeObjectURL(this.src);
                        this.src = null;
                    }
                      this.src = `data:image/jpeg;base64,${Buffer.from(response.data, 'binary').toString('base64')}`;
                  })
                  .catch(error => {
                      this.$error(`Failed to download image ${this.filename}`, error);
                      this.error = true;
                  });
          }
    },
    watch: {
        filename: function(val){
            this.loadImage();
        },
        site_id: function(val){
            this.loadImage();
        },
        endpoint: function(val){
            this.loadImage();
        }
    }
}
</script>

<style scoped>
    .missing-image{
        width: 250px;
        height: 250px;
        background-color: #8e8c8c;
    }

    .animated-background {
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background-color: #8e8c8c;
        background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
        background-size: 800px 104px;
        position: relative;
    }

    @keyframes placeHolderShimmer {
        0% {
          background-position: -800px 0
        }
        100% {
          background-position: 800px 0
        }
    }
</style>
